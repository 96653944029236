// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-blog-how-to-calculate-statistical-significance-tsx": () => import("./../../../src/pages/blog/how-to-calculate-statistical-significance.tsx" /* webpackChunkName: "component---src-pages-blog-how-to-calculate-statistical-significance-tsx" */),
  "component---src-pages-blog-how-to-run-ab-tests-tsx": () => import("./../../../src/pages/blog/how-to-run-ab-tests.tsx" /* webpackChunkName: "component---src-pages-blog-how-to-run-ab-tests-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-blog-what-is-statistical-significance-tsx": () => import("./../../../src/pages/blog/what-is-statistical-significance.tsx" /* webpackChunkName: "component---src-pages-blog-what-is-statistical-significance-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-products-celldb-index-tsx": () => import("./../../../src/pages/products/celldb/index.tsx" /* webpackChunkName: "component---src-pages-products-celldb-index-tsx" */),
  "component---src-pages-products-dmarc-index-tsx": () => import("./../../../src/pages/products/dmarc/index.tsx" /* webpackChunkName: "component---src-pages-products-dmarc-index-tsx" */),
  "component---src-pages-reporting-services-tsx": () => import("./../../../src/pages/reporting-services.tsx" /* webpackChunkName: "component---src-pages-reporting-services-tsx" */),
  "component---src-pages-tools-css-inliner-tsx": () => import("./../../../src/pages/tools/css-inliner.tsx" /* webpackChunkName: "component---src-pages-tools-css-inliner-tsx" */),
  "component---src-pages-tools-email-ab-test-calculator-tsx": () => import("./../../../src/pages/tools/email-ab-test-calculator.tsx" /* webpackChunkName: "component---src-pages-tools-email-ab-test-calculator-tsx" */),
  "component---src-pages-tools-statistical-significance-calculator-tsx": () => import("./../../../src/pages/tools/statistical-significance-calculator.tsx" /* webpackChunkName: "component---src-pages-tools-statistical-significance-calculator-tsx" */)
}

